import { Grid, Paper } from "@material-ui/core";
import { Container, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import QRCode from "react-qr-code";
import { useParams } from "react-router-dom";

const GeneradorCedula = () => {
      const { cc } = useParams();
      const [datosMascota, setDatosMascota] = useState([]);
      useEffect(() => {
            fetch(
                  `https://backend-cedulacion.onrender.com/cedulacion/datosmascota/${cc}`
            )
                  .then((res) => res.json())
                  .then((res) => setDatosMascota(res));
      }, []);

      const theme = createTheme();
      theme.typography.h6 = {
            fontSize: "0.6rem",
            "@media (max-width:600px)": {
                  fontSize: "0.7rem",
            },
      };

      theme.typography.subtitle2 = {
            fontSize: "0.9rem",
            "@media (max-width:600px)": {
                  fontSize: "0.4rem",
            },
      };

      theme.typography.h5 = {
            fontSize: "1.1rem",
            "@media (max-width:600px)": {
                  fontSize: "0.4rem",
            },
      };
      theme.typography.subtitle1 = {
            fontSize: "0.9rem",
            "@media (max-width:600px)": {
                  fontSize: "0.4rem",
            },
      };

      return (
            <Container>
                  <Grid
                        container
                        md="12"
                        justifyContent="center"
                        alignContent="center"
                        style={{ height: "100vh" }}
                        spacing={2}
                  >
                        {datosMascota.map((d) => (
                              <ThemeProvider theme={theme}>
                                    <Grid
                                          item
                                          md={12}
                                          style={{
                                                display: "flex",
                                                justifyContent: "center",
                                          }}
                                    >
                                          {/*PARTE DELANTERA*/}
                                          <Paper
                                                elevation={24}
                                                style={{
                                                      width: "450px",
                                                      height: "auto",
                                                      border: "solid 1px #057FC7",
                                                      padding: "20px",
                                                      background: "#e3edfd",
                                                }}
                                          >
                                                <Grid container md={12}>
                                                      <Grid
                                                            item
                                                            md={3}
                                                            style={{
                                                                  display: "flex",
                                                                  alignItems:
                                                                        "center",
                                                                  justifyContent:
                                                                        "end",
                                                            }}
                                                      >
                                                            <img
                                                                  src={
                                                                        process
                                                                              .env
                                                                              .PUBLIC_URL +
                                                                        "/images/bandera.jpg"
                                                                  }
                                                                  width="70"
                                                                  height="40"
                                                            />
                                                      </Grid>

                                                      <Grid
                                                            item
                                                            md={9}
                                                            style={{
                                                                  display: "flex",
                                                                  alignItems:
                                                                        "center",
                                                                  justifyContent:
                                                                        "start",
                                                                  flexDirection:
                                                                        "column",
                                                            }}
                                                      >
                                                            <Typography
                                                                  variant="h5"
                                                                  align="center"
                                                            >
                                                                  <b>
                                                                        REPUBLICA
                                                                        DE
                                                                        COLOMBIA
                                                                  </b>
                                                            </Typography>

                                                            <Typography
                                                                  variant="subtitle1"
                                                                  align="center"
                                                            >
                                                                  CÉDULA CANINA
                                                                  Y FELINA
                                                            </Typography>
                                                      </Grid>
                                                </Grid>

                                                <Grid container md={12}>
                                                      <Grid
                                                            item
                                                            md={5}
                                                            style={{
                                                                  display: "flex",
                                                                  alignItems:
                                                                        "center",
                                                                  justifyContent:
                                                                        "start",
                                                                  flexDirection:
                                                                        "column",
                                                            }}
                                                      >
                                                            <Grid
                                                                  item
                                                                  md={12}
                                                                  style={{
                                                                        display: "flex",
                                                                        justifyContent:
                                                                              "start",
                                                                        alignItems:
                                                                              "center",
                                                                  }}
                                                            >
                                                                  <div
                                                                        style={{
                                                                              width: "140px",
                                                                              height: "140px",
                                                                              border: "solid 1px #057FC7",
                                                                              borderRadius:
                                                                                    "5px",
                                                                        }}
                                                                  >
                                                                        <img
                                                                              src={
                                                                                    d.Url
                                                                              }
                                                                              width={
                                                                                    "100%"
                                                                              }
                                                                              height={
                                                                                    "100%"
                                                                              }
                                                                        />
                                                                  </div>
                                                            </Grid>

                                                            <Typography
                                                                  variant="subtitle2"
                                                                  align="center"
                                                                  sx={{ mb: 1 }}
                                                            >
                                                                  <b>
                                                                        ID:{" "}
                                                                        {d.cc}
                                                                  </b>
                                                            </Typography>
                                                      </Grid>
                                                      <Grid item md={7}>
                                                            <Typography
                                                                  variant="h6"
                                                                  align="start"
                                                                  sx={{ mt: 1 }}
                                                            >
                                                                  <b>NOMBRE</b>
                                                            </Typography>
                                                            <Typography
                                                                  variant="subtitle2"
                                                                  align="start"
                                                                  sx={{ mb: 1 }}
                                                            >
                                                                  <b>
                                                                        {
                                                                              d.Nombre
                                                                        }
                                                                  </b>
                                                            </Typography>
                                                            <Typography
                                                                  variant="h6"
                                                                  align="start"
                                                            >
                                                                  <b>ESPECIE</b>
                                                            </Typography>
                                                            <Typography
                                                                  variant="subtitle2"
                                                                  align="start"
                                                                  sx={{ mb: 1 }}
                                                            >
                                                                  <b>
                                                                        {(d.Tipo ===
                                                                              "PERRO" &&
                                                                              "CANINO") ||
                                                                              "FELINO"}
                                                                  </b>
                                                            </Typography>
                                                            <Typography
                                                                  variant="h6"
                                                                  align="start"
                                                            >
                                                                  <b>RAZA</b>
                                                            </Typography>
                                                            <Typography
                                                                  variant="subtitle2"
                                                                  align="start"
                                                                  sx={{ mb: 1 }}
                                                            >
                                                                  <b>
                                                                        {d.Raza}
                                                                  </b>
                                                            </Typography>
                                                            <Typography
                                                                  variant="h6"
                                                                  align="start"
                                                            >
                                                                  <b>
                                                                        FECHA DE
                                                                        NACIMIENTO
                                                                  </b>
                                                            </Typography>
                                                            <Typography
                                                                  variant="subtitle2"
                                                                  align="start"
                                                                  sx={{ mb: 1 }}
                                                            >
                                                                  <b>
                                                                        {
                                                                              d.FecNacimiento
                                                                        }
                                                                  </b>
                                                            </Typography>

                                                            <Typography
                                                                  variant="h6"
                                                                  align="start"
                                                            >
                                                                  <b>
                                                                        RESIDENCIA
                                                                  </b>
                                                            </Typography>
                                                            <Typography
                                                                  variant="subtitle2"
                                                                  align="start"
                                                                  sx={{ mb: 1 }}
                                                            >
                                                                  <b>
                                                                        {
                                                                              d.Lugar
                                                                        }
                                                                  </b>
                                                            </Typography>
                                                      </Grid>
                                                </Grid>
                                          </Paper>
                                    </Grid>
                                    {/*PARTE DELANTERA*/}
                                    <Grid
                                          item
                                          md={12}
                                          style={{
                                                display: "flex",
                                                justifyContent: "center",
                                          }}
                                    >
                                          <Paper
                                                elevation={24}
                                                style={{
                                                      width: "450px",
                                                      height: "auto",
                                                      border: "solid 1px #057FC7",
                                                      padding: "20px",
                                                      background: "#e3edfd",
                                                }}
                                          >
                                                <Grid
                                                      container
                                                      md={12}
                                                      style={{
                                                            marginBottom:
                                                                  "10px",
                                                      }}
                                                >
                                                      <Grid
                                                            item
                                                            md={5}
                                                            style={{
                                                                  display: "flex",
                                                                  justifyContent:
                                                                        "center",
                                                                  alignItems:
                                                                        "center",
                                                            }}
                                                      >
                                                            <div
                                                                  style={{
                                                                        maxHeight: 120,
                                                                        maxWidth: 120,
                                                                        width: "100%",
                                                                        border: "solid 3px grey",
                                                                        padding: "4px",
                                                                        borderRadius:
                                                                              "5px",
                                                                  }}
                                                            >
                                                                  <QRCode
                                                                        size={
                                                                              256
                                                                        }
                                                                        style={{
                                                                              height: "auto",
                                                                              maxWidth: "100%",
                                                                              width: "100%",
                                                                              borderRadius:
                                                                                    "5px",
                                                                        }}
                                                                        value={`https://desarrollo.rumas.com.co/informacion/${cc}`}
                                                                        viewBox={`0 0 256 256`}
                                                                  />
                                                            </div>
                                                      </Grid>
                                                      <Grid item md={7}>
                                                            <Typography
                                                                  variant="h6"
                                                                  align="start"
                                                            >
                                                                  <b>
                                                                        RESPONSABLE
                                                                  </b>
                                                            </Typography>
                                                            <Typography
                                                                  variant="subtitle2"
                                                                  align="start"
                                                                  sx={{ mb: 1 }}
                                                            >
                                                                  <b>
                                                                        {
                                                                              d.NomPropietario
                                                                        }
                                                                  </b>
                                                            </Typography>

                                                            <Typography
                                                                  variant="h6"
                                                                  align="start"
                                                            >
                                                                  <b>
                                                                        DOCUMENTO
                                                                        DE
                                                                        IDENTIDAD
                                                                  </b>
                                                            </Typography>
                                                            <Typography
                                                                  variant="subtitle2"
                                                                  align="start"
                                                                  sx={{ mb: 1 }}
                                                            >
                                                                  <b>
                                                                        {
                                                                              d.IdPropietario
                                                                        }
                                                                  </b>
                                                            </Typography>

                                                            <Typography
                                                                  variant="h6"
                                                                  align="start"
                                                            >
                                                                  <b>
                                                                        TELÉFONOS
                                                                  </b>
                                                            </Typography>
                                                            <Typography
                                                                  variant="subtitle2"
                                                                  align="start"
                                                                  sx={{ mb: 1 }}
                                                            >
                                                                  <b>
                                                                        {
                                                                              d.Telefono
                                                                        }
                                                                  </b>
                                                            </Typography>
                                                            <Typography
                                                                  variant="h6"
                                                                  align="start"
                                                            >
                                                                  <b>
                                                                        FECHA DE
                                                                        EMISIÓN
                                                                  </b>
                                                            </Typography>
                                                            <Typography
                                                                  variant="subtitle2"
                                                                  align="start"
                                                                  sx={{ mb: 1 }}
                                                            >
                                                                  <b>
                                                                        {
                                                                              d.FecExpedicion
                                                                        }
                                                                  </b>
                                                            </Typography>
                                                      </Grid>
                                                </Grid>

                                                <Grid container md={12} mt={2}>
                                                      <Grid
                                                            item
                                                            md={12}
                                                            style={{
                                                                  marginBottom:
                                                                        "10px",
                                                            }}
                                                      >
                                                            <Typography
                                                                  variant="h6"
                                                                  align="center"
                                                            >
                                                                  <b>
                                                                        Esta
                                                                        identificación
                                                                        ha sido
                                                                        emitida
                                                                        por el
                                                                        Registro
                                                                        Nacionalde
                                                                        Mascotas
                                                                        Colombia
                                                                  </b>
                                                            </Typography>

                                                            <Typography
                                                                  variant="h6"
                                                                  align="center"
                                                            >
                                                                  <b>
                                                                        Valida
                                                                        la
                                                                        información
                                                                        escaneando
                                                                        el
                                                                        código
                                                                        QR o
                                                                        ingresando
                                                                        a la
                                                                        plataforma{" "}
                                                                  </b>
                                                            </Typography>
                                                      </Grid>

                                                      <Grid
                                                            item
                                                            md={6}
                                                            style={{
                                                                  display: "flex",
                                                                  justifyContent:
                                                                        "center",
                                                            }}
                                                      >
                                                            <img
                                                                  src={
                                                                        process
                                                                              .env
                                                                              .PUBLIC_URL +
                                                                        "/images/logocedulacion.png"
                                                                  }
                                                                  width="180"
                                                                  height="50"
                                                            />
                                                      </Grid>

                                                      <Grid
                                                            item
                                                            md={6}
                                                            style={{
                                                                  display: "flex",
                                                                  justifyContent:
                                                                        "center",
                                                            }}
                                                      >
                                                            <img
                                                                  src={
                                                                        process
                                                                              .env
                                                                              .PUBLIC_URL +
                                                                        "/images/logofuneraria.png"
                                                                  }
                                                                  width="160"
                                                                  height="50"
                                                            />
                                                      </Grid>
                                                </Grid>
                                          </Paper>
                                    </Grid>
                              </ThemeProvider>
                        ))}
                  </Grid>
            </Container>
      );
};
export default GeneradorCedula;
